export const rejectedMessages = [
  "Oh god no please don't do this to me",
  "That was a mistake, right? It's okay. I'll give you one more chance.",
  "What are you, crazy?",
  "You're supposed to swipe right. NOT LEFT.",
  "OOF",
  "😢",
  "I didn't spend THIS MUCH TIME building this app just so you could reject me.",
  "This is the worst April Fools' Day ever.",
  "Why are you like this?",
];

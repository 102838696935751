export const sendMessages = [
    "Adam you are so great I love you so much.",
    "Hey there, handsome.",
    "Hey there, good looking",
    "Wow this app is so great haha I wish I was this funny",
    "Wow this app is hilarious haha I wish I was this funny but I'm not as funny as you, Adam Ginther.",
    "Wow this is the best April Fools ever",
    "Wow this app is so great I am going to share it with all my good looking friends right now",
    "Hello Adam Ginther have I ever told you how handsome you are?",
    "Hello Adam Ginther have I ever told you how good looking you are?",
    "Heyyyyyyyyyyyyyyyyyyyy...",
    "U up? I just wanted to chaaaaatttt...",
    "I’m not a photographer, but I can picture me and you together.",
    "Are you wearing space pants? Because you are out of this world.",
    "hhhhhhhhhheeyyyyyyyyyyyyyy tttttthhhhhheeeeeerreeeeeeeeeeee",
    "Hey handsome",
    "Hey good looking, what's cooking?",
    "Are you free for coffee sometime?",
    "Hey Adam, why don't we make like atoms and combine to make a molecule?",
    "lol wow you are so cool and funny and awesome I wish I was you",
    "Wow, it's that guy that men want to be and women want to be with",
    "Have I ever told you how good looking you are?",
    "Have I ever told you how good looking handsome you are?",
    "Wow this app is so good I guess it's time for me to delete my Tinder account",
    "I've been thinking about you all day, Adam Ginther",
    "Do I know you? ‘Cause you look a lot like my next boyfriend.",
    "They say Disneyland is the happiest place on earth. Well apparently, no one has ever been messaging you an an app like this.",
    "Are you religious? Because you’re the answer to all my prayers.",
    "I seem to have lost my phone number. Can I have yours?",
    "Are you a parking ticket? ‘Cause you’ve got fine written all over you.",
    "Are you sure you’re not tired? You’ve been running through my mind all day.",
    "Is there an airport nearby or is it my heart taking off?",
    "Would you please respond, so I can tell my friends I’ve talked to an angel?",
    "Plz respond",
    "Hi, how was heaven when you left it?",
    "Is your dad a terrorist? Cause you’re the bomb.",
    "Do you believe in love at first sight or should I swipe right again?",
    "Hey, you’re pretty and I’m cute. Together we’d be Pretty Cute.",
    "Can I follow you home? Cause my parents always told me to follow my dreams.",
    "Is your name Google? Because you have everything I’ve been searching for.",
    "You don’t need keys to drive me crazy.",
    "You remind me of a magnet, because you sure are attracting me over here!",
    "Is my phone overheating or are all the men on this app just way too hot?",
    "Do you like raisins? How do you feel about a date?",
    "If I could rearrange the alphabet, I’d put ‘U’ and ‘I’ together.",
    "Life without you is like a broken pencil…pointless."
  ];
  